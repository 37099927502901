import { createReducer, on } from '@ngrx/store';
import { BrandActions } from './brand.actions';

export const brandFeatureKey = 'brand';

export interface State {
  status: string,
  data: any
}

export const initialState: State = {
  status: 'pending',
  data: null
};

export const reducer = createReducer(
  initialState,
  on(
    BrandActions.loadBrandsSuccess, (state, action) => {
      return {
        ...state,
        status: 'success',
        data: action.data
      }
    }
  )
);


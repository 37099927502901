import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const BrandActions = createActionGroup({
  source: 'Brand',
  events: {
    'Load Brands': emptyProps(),
    'Load Brands Success': props<{ data: unknown }>(),
    'Load Brands Failure': props<{ error: unknown }>(),
  }
});

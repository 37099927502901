import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../app.store';
import { brandFeatureKey } from './brand.reducer';

const getBrand = createFeatureSelector<AppState>(brandFeatureKey);

export const getBrandsData = createSelector(
    getBrand,
    (state: any) => {
        return state.data
    }
)